import { Pipe, PipeTransform } from '@angular/core';
import { MarkerIdentity } from '../pdf-signature-customizer/pdf-signature-customizer.model';
import { Router } from '@angular/router';

@Pipe({
  name: 'questionSettingPermission',
})
export class QuestionSettingPermissionPipe implements PipeTransform {
  constructor(private router: Router) {}
  transform(markerIdentity: MarkerIdentity): boolean {
    if (markerIdentity.optionalType !== 'question_positions') {
      return false;
    }

    const url = this.router.url;
    if (url.includes('memos/upload')) {
      return true;
    }

    return false;
  }
}
