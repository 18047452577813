<div class="d-flex flex-column flex-grow-1 elem-gap custom-box p-2">
  <p class="m-0">
    <b style="text-decoration: underline">{{'QUESTION.Question no'|translate}} {{_question.question_sequence}}</b>
    <b *ngIf="_question.require"
      class="text-danger">&nbsp;*</b>
  </p>

  <app-text-editable class="pb-2"
    [(text)]="_question.question"
    [placeholder]="'QUESTION.Please fill question'|translate"
    [enableEdit]="enableEdit"></app-text-editable>

  <div [ngSwitch]="_question.form_type"
    class="w-100">
    <ng-container *ngSwitchCase="'text_line'">
      <textarea type="text"
        [disabled]="disabled"
        class="form-control"
        rows="3"
        [attr.maxlength]="_question?.options?.limitLength > 0 ? _question?.options?.limitLength : null"
        [(ngModel)]="_question.data"
        (ngModelChange)="onSetTextLine()"></textarea>
      <span *ngIf="_question?.options?.limitLength"
        style="font-size: 12px; color: #808080;">
        {{ 'EMAIL-SETTING.CHAR-REMAIN'|translate }}: {{_question?.options?.limitLength - _question.data?.length}}
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'text_dropdown'">
      <ng-select class="custom"
        [items]="_question.items"
        [clearable]="!_question.require"
        [multiple]="false"
        [(ngModel)]="_question.data"
        (change)="onSelectDropdown($event)"></ng-select>
    </ng-container>

    <ng-container *ngSwitchCase="'radio'">
      <div class="w-100 d-flex flex-column">
        <label *ngFor="let choice of _question.choices"
          [for]="choice.form_id"
          class="d-flex align-items-center justify-content-start pointer w-100">
          <input type="radio"
            class="mr-2 pointer"
            [disabled]="disabled"
            [id]="choice.form_id"
            [name]="'radio' + _question.question_sequence"
            [value]="choice.text"
            [(ngModel)]="choice.data"
            (click)="onCheckRadio($event)"/>
          <app-text-editable
            class="w-100"
            [enableEdit]="enableEdit"
            [(text)]="choice.text"></app-text-editable>
        </label>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'radio_checkbox'">
      <div class="w-100 d-flex flex-column">
        <label *ngFor="let choice of _question.choices"
          [for]="choice.form_id"
          class="d-flex align-items-center justify-content-start pointer w-100">
          <input type="checkbox"
            class="mr-2 pointer"
            [disabled]="disabled"
            [id]="choice.form_id"
            [name]="'checkbox' + _question.question_sequence"
            [(ngModel)]="choice.data"
            (ngModelChange)="onCheckbox($event)"/>
          <app-text-editable
            class="w-100"
            [enableEdit]="enableEdit"
            [(text)]="choice.text"></app-text-editable>
        </label>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <input type="text"
        readonly
        class="form-control input-container w-100"
        name="dp"
        ngbDatepicker
        (click)="invf1.toggle();"
        #invf1="ngbDatepicker"
        [disabled]="disabled"
        [(ngModel)]="_question.options.rawDate"
        (dateSelect)="onSelectDate($event)"
        autocomplete="off">

      <span class="span-calendar">
        <i _ngcontent-kkw-c5=""
          class="fa fa-calendar i-calendar"
          aria-hidden="true"
          ngbDatepicker
          (click)="invf1.toggle()"></i>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <input type="number"
        class="form-control w-100"
        [disabled]="disabled"
        [(ngModel)]="_question.data"
        (ngModelChange)="onSetNumber($event)">
    </ng-container>
  </div>

  <div *ngIf="!disabled"
    class="w-100"
    style="min-height: 22px">
    <span class="text-danger"
      style="font-size: 12px">
      <ng-container *ngIf="_question.hasError && _question.errorMsg">
      {{_question.errorMsg}}
      </ng-container>
    </span>
  </div>
</div>
