export const QUESTION_COLOR_SET = [
  {
    first: '#2fb4ff',
    second: '#e8f1fcb3',
  },
  {
    first: '#8EAAFE',
    second: '#8EAAFE33',
  },
  {
    first: '#C2A1E2',
    second: '#C2A1E233',
  },
  {
    first: '#FFAC6D',
    second: '#FFAC6D33',
  },
  {
    first: '#A2EB27',
    second: '#A2EB2733',
  },
  {
    first: '#F886D8',
    second: '#F886D833',
  },
  {
    first: '#FFB629',
    second: '#FFB62933',
  },
];

export function getQuestionColorSet(
  n: number,
  type: 'first' | 'second' = 'first',
): string {
  const index = n % QUESTION_COLOR_SET.length;
  return QUESTION_COLOR_SET[index][type];
}
