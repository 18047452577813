<div class="modal-container">
  <div class="question-header">
    <div class="header-content">
      <h4 [ngSwitch]="modalMode"
        class="font-weight-bold m-0">
        <ng-container *ngSwitchCase="'setting'">
          {{'QUESTION.Order Question'|translate}}
        </ng-container>
        <ng-container *ngSwitchCase="'answer'">
          {{'QUESTION.Ans Question'|translate}}
        </ng-container>
      </h4>
      <button type="button"
        class="close"
        aria-label="Close"
        (click)="sv.closeModal()">
        <span class="far fa-times pl-5 pr-3" aria-hidden="true"></span>
      </button>
    </div>
  </div>

  <div class="question-body custom-box">

    <div [ngSwitch]="modalMode"
      class="body-content">
      <ng-container *ngSwitchCase="'setting'">
        <div class="mb-3 w-100">
          <label>{{'LOA.APPROVAL'|translate}}</label>
          <ng-select [items]="sv.markers"
            bindLabel="name"
            bindValue="name"
            [clearable]="false"
            [multiple]="false"
            [(ngModel)]="sv.selectedMarkerName"
            (change)="onSelectApprover($event)"></ng-select>
        </div>

        <div class="drag-list-wrapper"
          cdkScrollable>
          <div class="example-list"
            cdkDropList
            (cdkDropListDropped)="onDrop($event)">
            <div *ngFor="let q of sv.formattedQuestions[sv.selectedMarkerName];let i = index"
              class="question-card example-box"
              cdkDrag
              cdkDragLockAxis="y">
              <button cdkDragHandle
                class="btn-none btn-drag">
                <svg-icon src="/assets/svg/carbon_draggable.svg"></svg-icon>
              </button>

              <app-question-form class="flex-grow-1"
                [question]="q"
                [enableEdit]="true"></app-question-form>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'answer'">
        <div class="drag-list-wrapper">
          <div class="example-list">
            <div *ngFor="let q of sv.formattedQuestions[sv.selectedMarkerName];let i = index"
              class="question-card"
              [ngClass]="{'card-error': q?.hasError}">
              <app-question-form class="flex-grow-1"
                [question]="q"
                [disabled]="modalMode === 'setting'"
              ></app-question-form>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="question-footer">
    <div class="footer-content">
      <button class="btn p-btn btn-outline btn-w-80"
        [style.--c]="themeList?.header_table_color"
        (click)="sv.closeModal()">
        {{ 'MEMOS.CANCEL' | translate }}
      </button>

      <button class="btn p-btn btn-w-80"
        type="button"
        appSetTheme
        [ladda]="saveAnsLoading"
        (click)="onConfirm()">
        {{ 'MEMOS.CONFIRM' | translate }}
      </button>
    </div>
  </div>
</div>
