import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'questionToolsPermission',
})
export class QuestionToolsPermissionPipe implements PipeTransform {
  constructor(private router: Router) {}
  transform(currentMarker): boolean {
    const url = this.router.url;
    if (url.includes('template/create')) {
      return true;
    }

    if (url.includes('memos/upload')) {
      return !!currentMarker;
    }

    return false;
  }
}
