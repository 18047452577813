import { Component, Input } from '@angular/core';
import { FormatQuestion } from '../question.model';
import { TranslateService } from '@ngx-translate/core';
import { QuestionModalService } from '../question-modal/question-modal.service';
import { DateFormatService } from '../../../../../../shared/service/date-format.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent {
  _question: FormatQuestion;
  @Input() enableEdit = false;

  constructor(
    private translate: TranslateService,
    private questionModalService: QuestionModalService,
    private dateFormatService: DateFormatService,
  ) {}

  @Input() set question(v: FormatQuestion) {
    this._question = v;
  }
  @Input() disabled = true;

  onSetTextLine() {
    const passed = this.questionModalService.validateRequired(
      this._question,
    );
    if (!passed) {
      return;
    }
    this._question.hasError = false;
    this._question.errorMsg = null;
  }

  onSelectDropdown(v) {
    if (this.disabled) {
      setTimeout(() => {
        this._question.data =
          this._question?.options?.default ?? null;
      }, 1);
      return;
    }

    const passed = this.questionModalService.validateRequired(
      this._question,
    );
    if (!passed) {
      return;
    }
    this._question.hasError = false;
    this._question.errorMsg = null;
  }

  onCheckbox(v) {
    const passed = this.questionModalService.validateRequired(
      this._question,
    );
    if (!passed) {
      return;
    }
    this._question.hasError = false;
    this._question.errorMsg = null;
  }

  onCheckRadio(v) {
    this._question.choices.forEach((c) => {
      if (c.text === v) {
        c.data = !!c.data ? null : v;
      } else {
        c.data = null;
      }
    });
    setTimeout(() => {
      const passed = this.questionModalService.validateRequired(
        this._question,
      );
      if (!passed) {
        return;
      }
      this._question.hasError = false;
      this._question.errorMsg = null;
    }, 10);
  }

  onSelectDate(date: NgbDate) {
    const formatted = this.dateFormatService.getDateLabelWithDate(
      date,
      this._question.options?.dateFormat ??
        this.dateFormatService.dateFormatListEN[0].value,
    );
    this._question.data = formatted;

    const passed = this.questionModalService.validateRequired(
      this._question,
    );
    if (!passed) {
      return;
    }
    this._question.hasError = false;
    this._question.errorMsg = null;
  }

  onSetNumber(v) {
    let passed = this.questionModalService.validateRequired(
      this._question,
    );
    if (!passed) {
      return;
    }
    passed = this.questionModalService.validateMinMax(this._question);
    if (!passed) {
      return;
    }
    this._question.hasError = false;
    this._question.errorMsg = null;
  }
}
