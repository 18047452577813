import { Injectable } from '@angular/core';
import { Marker } from '../pdf-signature-customizer/pdf-signature-customizer.model';
import { FormInputMarkerIdentity } from '../form-inputs/form-inputs.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../../../core/http/api.service';
import { API_URL } from '../../../../../shared/service/api.constant';
import { Observable } from 'rxjs';
import {
  FormatQuestion,
  SaveAnsQuestionResponse,
} from './question.model';
import { map } from 'rxjs/operators';
import { generateUUID } from '../../../../../shared/utils/common.util';

@Injectable({ providedIn: 'root' })
export class QuestionsService {
  constructor(
    private translate: TranslateService,
    private http: ApiService,
  ) {}

  initQuestionMarkerIdentityData(
    marker: Marker,
  ): Pick<FormInputMarkerIdentity, 'question' | 'question_sequence'> {
    return {
      question: null,
      question_sequence: this.calculateQuestionSequence(marker),
    };
  }

  calculateQuestionSequence(marker: Marker): number {
    if (!marker.question_positions) {
      marker.question_positions = [];
    }

    const fieldNames = new Set();
    const fieldSequence: any = {};
    marker.question_positions.forEach(
      (markerIdentity: FormInputMarkerIdentity) => {
        const options = markerIdentity.options as any;
        if (!options.fieldName) {
          return;
        }
        if (fieldNames.has(options.fieldName)) {
          return;
        }
        fieldNames.add(options.fieldName);
        fieldSequence[options.fieldName] = {
          no: fieldNames.size,
          uuid: generateUUID(),
        };
      },
    );

    // reorder question sequence
    for (const targetName in fieldSequence) {
      marker.question_positions.forEach(
        (markerIdentity: FormInputMarkerIdentity) => {
          const options = markerIdentity.options as any;
          if (options.fieldName !== targetName) {
            return;
          }
          markerIdentity.question_sequence =
            fieldSequence[targetName].no;

          if (
            markerIdentity.formInputType !== 'radio' &&
            markerIdentity.formInputType !== 'radio_checkbox'
          ) {
            markerIdentity.formInputId =
              fieldSequence[targetName].uuid;
          }
        },
      );
    }

    return fieldNames.size + 1; // next sequence
  }

  updateQuestionInSameFieldName(
    marker: Marker,
    updatedMarkerIdentity: any,
    updateField = 'question',
  ) {
    marker?.question_positions.forEach((markerIdentity: any) => {
      if (
        markerIdentity?.options?.fieldName ===
          updatedMarkerIdentity?.options?.fieldName &&
        markerIdentity?.formInputType ===
          updatedMarkerIdentity?.formInputType
      ) {
        if (['fieldValue'].includes(updateField)) {
          return;
        }
        if (['question', 'require', 'data'].includes(updateField)) {
          markerIdentity[updateField] =
            updatedMarkerIdentity[updateField];
        } else {
          markerIdentity.options[updateField] =
            updatedMarkerIdentity.options[updateField];
        }
      }
    });
  }

  validateQuestionForm(markers: any[]): string[] {
    let msg = [];

    markers.forEach((marker) => {
      if (!marker?.question_positions) {
        return;
      }
      marker.question_positions.forEach((obj) => {
        const pos = obj as FormInputMarkerIdentity;

        if (pos.formInputType === 'text_dropdown') {
          msg.push(...this.validateFormDropdown(pos));
        }
        if (
          pos.formInputType === 'radio' ||
          pos.formInputType === 'radio_checkbox'
        ) {
          msg.push(...this.validateFormRadio(pos));
        }

        msg.push(...this.validateAllForm(pos));
      });
    });

    msg = msg.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    return msg;
  }

  validateFormDropdown(
    marker: FormInputMarkerIdentity<'text_dropdown'>,
  ): string[] {
    const msg = [];
    if (!marker.options.items.length) {
      msg.push(
        this.translate.instant(
          'MEMOS.SSF.DROPDOWN.ITEMS-IS-REQUIRED',
        ),
      );
    }
    return msg;
  }

  validateFormRadio(marker): string[] {
    const msg = [];
    if (!marker.options?.fieldValue) {
      msg.push(
        this.translate.instant('UPLOAD.Field Value is required'),
      );
    }
    if (marker.options.duplicateValue) {
      msg.push(
        this.translate.instant(
          'UPLOAD.This field value is already taken',
        ),
      );
    }
    return msg;
  }

  validateAllForm(marker): string[] {
    const msg = [];
    if (marker.options) {
      if (!marker.options?.fieldName) {
        msg.push(
          this.translate.instant('UPLOAD.Field Name is required'),
        );
      }
    }
    if (marker.options?.duplicateName) {
      msg.push(
        this.translate.instant(
          'UPLOAD.This field name is already taken',
        ),
      );
    }
    if (!marker?.question || !marker?.question?.trim()) {
      msg.push(
        this.translate.instant('QUESTION.Question is required'),
      );
    }
    return msg;
  }

  hasAnyInMarkers(markers: Marker[]): boolean {
    return markers.some(
      (marker) => marker?.question_positions?.length > 0,
    );
  }

  hasQuestionInCurrentLevel(
    curLevel: number,
    markers: Marker[],
  ): boolean {
    if (!curLevel) {
      return false;
    }

    const marker = markers.find((m) => m.level === curLevel);
    if (!marker) {
      return false;
    }

    return marker.question_positions?.length > 0;
  }

  reformatQuestions(questions: any[]): Observable<FormatQuestion[]> {
    return this.http
      .post(API_URL.reformat_question, {
        question_positions: questions,
      })
      .pipe(map((res: any) => res?.questions ?? []));
  }

  saveAnsQuestions(memoId, questions) {
    return this.http.patch<SaveAnsQuestionResponse>(
      API_URL.memos_questions + memoId + '/',
      {
        questions,
      },
    );
  }

  previewAns(memoId) {
    return this.http.getBlob<Blob>(
      API_URL.memos_questions + memoId + '/preview/',
    );
  }
}
