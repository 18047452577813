import { Component, Input, OnDestroy } from '@angular/core';
import { QuestionModalService } from './question-modal.service';
import { Marker } from '../../pdf-signature-customizer/pdf-signature-customizer.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { QuestionModalMode } from '../question.model';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../../../../core/services/alert.service';
import { ThemeService } from '../../../../../../shared/service/theme.service';

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss'],
})
export class QuestionModalComponent implements OnDestroy {
  destroy$ = new Subject();
  modalMode: QuestionModalMode | null;
  saveAnsLoading = false;
  themeList;

  constructor(
    public sv: QuestionModalService,
    private alert: AlertService,
    private themeService: ThemeService,
  ) {
    this.themeService.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme) => {
        this.themeList = theme;
      });

    this.sv.modalMode$
      .pipe(takeUntil(this.destroy$))
      .subscribe((mode) => {
        this.modalMode = mode;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectApprover(marker: Marker) {
    this.sv.initQuestions(marker);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    this.sv.reOrderQuestions(event.previousIndex, event.currentIndex);
  }

  onConfirm() {
    if (this.modalMode === 'setting') {
      this.sv.confirmEditQuestion();
      this.sv.closeModal();
      return;
    }
    if (this.modalMode === 'answer') {
      // validate error
      const passed = this.sv.validateAns();
      if (!passed) {
        return;
      }

      this.saveAnsLoading = true;
      this.sv.saveAns().subscribe({
        next: () => {
          this.saveAnsLoading = false;
          this.sv.closeModal();
        },
        error: (err) => {
          this.saveAnsLoading = false;
          this.alert.error('Cannot save answer');
        },
      });
      return;
    }
  }
}
