import { NgModule } from '@angular/core';
import { QuestionColorPipe } from './question-color.pipe';
import { QuestionFillPermissionPipe } from './question-fill-permission.pipe';
import { QuestionMarkerColorPipe } from './question-marker-color.pipe';
import { QuestionModalComponent } from './question-modal/question-modal.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { QuestionSettingPermissionPipe } from './question-setting-permission.pipe';
import { QuestionToolsPermissionPipe } from './question-tools-permission.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { QuestionFormComponent } from './question-form/question-form.component';
import {
  CDK_DRAG_CONFIG,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { QuestionPreviewComponent } from './question-preview/question-preview.component';
import { TextEditableComponent } from './text-editable/text-editable.component';

@NgModule({
  declarations: [
    QuestionColorPipe,
    QuestionFillPermissionPipe,
    QuestionSettingPermissionPipe,
    QuestionToolsPermissionPipe,
    QuestionMarkerColorPipe,
    QuestionModalComponent,
    QuestionFormComponent,
    QuestionPreviewComponent,
    TextEditableComponent,
  ],
  imports: [
    ClickOutsideModule,
    AngularSvgIconModule,
    TranslateModule,
    SharedModule,
    DragDropModule,
  ],
  exports: [
    QuestionColorPipe,
    QuestionFillPermissionPipe,
    QuestionSettingPermissionPipe,
    QuestionToolsPermissionPipe,
    QuestionMarkerColorPipe,
    QuestionModalComponent,
    QuestionPreviewComponent,
  ],
  providers: [
    {
      provide: CDK_DRAG_CONFIG,
      useValue: {
        dragStartThreshold: 0,
        pointerDirectionChangeThreshold: 5,
        zIndex: 100000,
      },
    },
  ],
})
export class QuestionsModule {}
