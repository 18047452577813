<div class="template-custom-pdf-view"
    #pdfViewer
    [style.height.px]="originalPdfHeight"
    (resized)="onPdfViewResized($event)">
    <ng-container *ngIf="originalPageView && showStickyNote">
        <ng-container
            *ngFor="let marker of filterStickyNotesByPage; let index=index; trackBy: dragdropGroupTrackByFunc">
            <div class="dragdrop"
                #dragdropDiv
                [class.disable-active-effect]="false"
                [ngStyle]="{'background-color': marker.backgroundColor}"
                [ngClass]="{'selected': selectedStickyNoteID === marker.id || marker.enableSetting}"
                cdkDrag
                [cdkDragFreeDragPosition]="marker.screenCoordinate"
                [cdkDragDisabled]="!canEditOrDelete(marker)"
                (cdkDragStarted)="onDragStart(marker)"
                (cdkDragMoved)="onDragMove($event)"
                (cdkDragEnded)="onChange(marker, $event)">
                <div class="dragdrop-sticky-note">
                    <div *ngIf="!allowEdit"
                        (click)="selectStickyNote(marker)"
                        class="dragdrop-sticky-note-content"
                        [style.line-height.px]="marker.fontSize | relativePdfLineHeight: pdfElementHeight"
                        [style.font-size.px]="marker.fontSize | relativePdfFontSize: pdfElementHeight"
                        [style.font-family]="getFontStyle(marker.fontStyle)"
                        [style.color]="marker.color">
                        <p class="mb-3">{{ marker.name | translate }} {{ marker.date && ( marker.date | date:
                            'dd/MM/yyyy HH:mm') }}</p>
                        <div class="row no-gutters p-1 position-relative">
                            <textarea class="custom-textarea"
                                [ngStyle]="{'height': getTextAreaHeight(textArea)}"
                                [ngClass]="{'editable': selectedID === marker.id}"
                                [disabled]="selectedID !== marker.id"
                                [style.line-height.px]="marker.fontSize | relativePdfLineHeight: pdfElementHeight"
                                [style.font-size.px]="marker.fontSize | relativePdfFontSize: pdfElementHeight"
                                [style.font-family]="getFontStyle(marker.fontStyle)"
                                [style.color]="marker.color"
                                #textArea
                                (ngModelChange)="textAreaAdjust(textArea)"
                                [(ngModel)]="marker.string"
                                *ngIf="selectedID === marker.id">
                            </textarea>
                            <div *ngIf="selectedID !== marker.id"
                                class="textarea-showoff">
                                {{marker.string}}
                            </div>
                            <div class="send-icon"
                                *ngIf="selectedID === marker.id"
                                (click)="submit(marker)">
                                <img src="assets/svg/icon-usage/send.svg"
                                    alt="send sticky note">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="edit-icon"
                    *ngIf="canEditOrDelete(marker)">
                    <svg-icon src="assets/svg/ellipsis-vertical.svg"
                        (click)="onClickDragDropSetting(marker)">
                    </svg-icon>
                </div>

                <div class="delete-icon"
                    *ngIf="canEditOrDelete(marker)"
                    (click)="openDeleteConfirmation(deleteConfirmationModal, marker)">
                    <i class="fa fa-times"></i>
                </div>
                <div *ngIf="marker.enableSetting && selectedID === marker.id"
                    class="option-popup-window"
                    [ngStyle]="getOptionPopupWindowPosition(marker, dragdropDiv)">
                    <div class="row mb-1">
                        <div class="col-3 d-flex justify-content-end">
                            <span class="delete-marker"
                                (click)="openDeleteConfirmation(deleteConfirmationModal, marker)">
                                {{'MEMOS.DELETE'|translate}}
                            </span>
                        </div>
                    </div>

                    <!-- size -->
                    <div class="option-form">
                        <label for="fontSizeInput">{{'UPLOAD.SIZE'|translate}}<span class="text-red">*</span>
                        </label>
                        <input id="fontSizeInput"
                            type="number"
                            class="my-2"
                            [value]="marker.fontSize | relativePdfFontSize: pdfElementHeight"
                            (change)="onChangeFontSize(marker, $event.target.value)">
                    </div>
                    <!-- font -->
                    <div class="option-form">
                        <label for="fontStyleSelection">{{'UPLOAD.FONT'|translate}}<span class="text-red">*</span>
                        </label>
                        <ng-select class="my-2"
                            id="fontStyleSelection"
                            (change)="onChangeFontStyle(marker, $event)"
                            [items]="fontList"
                            [(ngModel)]="marker.fontStyle"
                            bindLabel="name"
                            placeholder="select font"
                            [notFoundText]="'SELECT.No items found'|translate"
                            [clearable]="false"
                            appendTo=".template-custom-pdf-view"
                            [closeOnSelect]="false">
                        </ng-select>
                    </div>

                    <!-- color -->
                    <div class="option-form">
                        <label>{{'UPLOAD.COLOR'|translate}}</label>
                        <!-- custom color-->
                        <div class="color-list row mt-2 mr-0">
                            <div class="color-item-border col-2 p-0"
                                style="margin-left: 2px">
                                <div class="color-item"
                                    [style.background]="marker.color"></div>
                            </div>
                            <div class="color-custom my-1 col p-0">
                                <input #ignoredInput
                                    type="text"
                                    class="form-control"
                                    [style.color]="marker.color"
                                    [cpIgnoredElements]="[ignoredInput]"
                                    [(ngModel)]="marker.color"
                                    [(colorPicker)]="marker.color" />
                            </div>
                        </div>
                        <!-- default color list-->
                        <div class="color-list my-1"
                            style="margin-left: -4px">
                            <span class="color-item-border"
                                *ngFor="let color of signatureColorList"
                                [style.border-color]="color === marker.color ? color : 'white'"
                                (click)="onSelectedColor(marker, color)">
                                <span class="color-item"
                                    [style.background]="color"></span>
                            </span>
                            <span class="ml-2 btn-custom-color"
                                (click)="toggleCustomColor(marker)">+ Add</span>
                        </div>
                        <div class="color-custom my-1"
                            *ngIf="marker.showCustomColor">
                            <color-sketch disableAlpha="true"
                                [color]="marker.color"
                                [(ngModel)]="marker.color"
                                (onChangeComplete)="onChange(marker, $event.color.hex, marker.name, 'color')">
                            </color-sketch>
                        </div>

                        <!-- default background color list-->
                        <div class="py-3">
                            <label>{{'UPLOAD.BACKGROUND'|translate}}</label>
                            <div class="color-list my-1"
                                style="margin-left: -4px">
                                <span class="color-item-border"
                                    *ngFor="let bgColor of backgroundColorList"
                                    [style.border-color]="bgColor === marker.backgroundColor ? bgColor : 'white'"
                                    (click)="onSelectedBgColor(marker, bgColor)">
                                    <span class="color-item"
                                        [style.background]="bgColor"></span>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>
    </ng-container>

</div>

<ng-template #deleteConfirmationModal>
    <div class="modal-header red">
        <h4 class="modal-title">
            {{ 'MEMOS.CONFIRM-DELETE' |translate}}
        </h4>
        <button type="button"
            class="close"
            aria-label="Close"
            (click)="dismissDelete()">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body text-center f-18">
        {{ 'MEMOS.TEXT' |translate}}
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline btn-confirm"
            [style.--c]="themeList?.header_table_color"
            (click)="dismissDelete()">
            {{'MEMOS.CANCEL'|translate}}
        </button>
        <button class="btn btn-confirm"
            type="button"
            appSetTheme
            (click)="deleteStickyNote()">
            {{'MEMOS.CONFIRM'|translate}}
        </button>
    </div>
</ng-template>
