import {
  Component,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QuestionsService } from '../questions.service';
import { AlertService } from '../../../../../../core/services/alert.service';

@Component({
  selector: 'app-question-preview',
  templateUrl: './question-preview.component.html',
  styleUrls: ['./question-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionPreviewComponent {
  pdfSrc: any;
  modal: NgbModalRef;
  isLoading = false;

  @Output() closed = new EventEmitter();
  @Output() confirm = new EventEmitter();

  @ViewChild('content', { static: true }) content;

  constructor(
    private modalService: NgbModal,
    private questionsService: QuestionsService,
    private alert: AlertService,
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    this.closePreview();
  }

  open(memoId): void {
    this.isLoading = true;
    this.questionsService.previewAns(memoId).subscribe({
      next: (blob) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.pdfSrc = new Uint8Array(
            fileReader.result as ArrayBuffer,
          );

          this.modal = this.modalService.open(this.content, {
            size: 'lg',
            centered: true,
            windowClass: 'transparent-modal',
            backdrop: 'static',
          });
          this.isLoading = false;
        };
        fileReader.readAsArrayBuffer(blob);
      },
      error: (err) => {
        this.isLoading = false;
        this.alert.error('Cannot Preview PDF');
        this.closePreview();
      },
    });
  }

  closePreview(): void {
    if (this.modal) {
      this.modal.close();
    }
    this.modal = null;
    this.closed.emit({});
  }
}
