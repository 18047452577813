<ng-template #content
  let-modal
  let-close="close">
  <div class="preview-helper d-flex flex-row flex-md-column"
    style="gap:10px">
    <div>
      <a class="f-30 text-white icon-circle"
        href="javascript:void(0)"
        (click)="closePreview()">
        <i class="fas fa-times"></i>
      </a>
    </div>
  </div>

  <div class="preview-content">
    <div class="pdf-viewer-viewer-window"
      *ngIf="!isLoading">
      <pdf-viewer
        [src]="pdfSrc"
        [original-size]="true"
        [autoresize]="true"
        [fit-to-page]="true"
        [show-all]="true"
        [stick-to-page]="true"
        style="width: 100%; height: 100%"
      ></pdf-viewer>
    </div>
    <button class="mt-3 py-2 py-lg-3 btn w-100"
      type="button"
      appSetTheme
      (click)="closePreview();confirm.emit()">
      <span class="mr-3">{{'QUESTION.Next'|translate}}</span>
      <svg-icon src="assets/svg/carbon_next-outline.svg"
        [svgStyle]="{'fill': '#ffffff', 'height.px': 24, 'width.px': 24}"></svg-icon>
    </button>
  </div>
</ng-template>
