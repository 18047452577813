import { Pipe, PipeTransform } from '@angular/core';
import { getQuestionColorSet } from './questions.constant';
import { Marker } from '../pdf-signature-customizer/pdf-signature-customizer.model';

@Pipe({
  name: 'questionMarkerColor',
})
export class QuestionMarkerColorPipe implements PipeTransform {
  transform(
    marker: Marker,
    type: 'color' | 'color2' = 'color',
  ): string {
    if (type in marker) {
      return marker[type];
    }

    return getQuestionColorSet(
      0,
      type === 'color2' ? 'second' : 'first',
    );
  }
}
