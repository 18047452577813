<div *ngIf="!openEdit"
  class="w-100 d-flex">
  <ng-container *ngIf="text else placeholderTemplate">
    <span style="word-break: break-all">{{text}}</span>
  </ng-container>
  <ng-template #placeholderTemplate>
    <span style="word-break: break-all;color: #808080;">
      {{placeholder ?? ('QUESTION.Please fill value'|translate)}}
    </span>
  </ng-template>

  <svg-icon *ngIf="enableEdit"
    src="/assets/svg/edit.svg"
    class="ml-2 pointer"
    (click)="openEditMode()"></svg-icon>
</div>

<div *ngIf="openEdit"
  class="w-100 d-flex mb-2">
  <textarea type="text"
    class="form-control"
    rows="3"
    [(ngModel)]="tmpText"
    [placeholder]="placeholder ?? ('QUESTION.Please fill value'|translate)"></textarea>

  <div class="ml-2 d-flex flex-column">
    <svg-icon src="/assets/svg/carbon_checkmark.svg"
      class="pointer fill-success"
      (click)="onConfirm()"></svg-icon>
    <svg-icon src="/assets/svg/close.svg"
      class="pointer fill-danger"
      (click)="closeEditMode()"></svg-icon>
  </div>
</div>
