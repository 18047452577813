import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Marker } from '../../pdf-signature-customizer/pdf-signature-customizer.model';
import { QuestionsService } from '../questions.service';
import { FormatQuestion, QuestionModalMode } from '../question.model';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FormInputMarkerIdentity } from '../../form-inputs/form-inputs.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionModalService {
  private _isOpen = false;
  private openSubject$ = new BehaviorSubject<boolean>(this._isOpen);
  isOpen$ = this.openSubject$.asObservable();
  memoId;
  modalMode$ = new BehaviorSubject<QuestionModalMode>(null);

  markers: Marker[];
  selectedMarkerName: string;
  formattedQuestions: { [name: string]: FormatQuestion[] } = {};

  constructor(
    private questionService: QuestionsService,
    private translate: TranslateService,
  ) {}

  get curQuestionGroup(): FormatQuestion[] {
    return this.formattedQuestions[this.selectedMarkerName];
  }

  openModal(
    markers?: Marker[],
    current?: Marker,
    mode: QuestionModalMode = null,
    memoId?,
  ) {
    if (mode === 'answer') {
      this.memoId = memoId;
    }
    this._isOpen = true;
    if (markers) {
      this.markers = this.filterQuestionMarkers(markers);
      let selectedMarker = this.markers[0];
      this.selectedMarkerName = this.markers[0].name;
      if (current && current?.question_positions?.length > 0) {
        selectedMarker = current;
        this.selectedMarkerName = current.name;
      }

      this.initQuestions(selectedMarker);
    }
    this.openSubject$.next(this._isOpen);
    this.modalMode$.next(mode);
  }

  closeModal() {
    this.clearData();
    this._isOpen = false;
    this.openSubject$.next(this._isOpen);
  }

  initQuestions(marker: Marker) {
    if (marker.name in this.formattedQuestions) {
      return;
    }
    this.questionService
      .reformatQuestions(marker.question_positions)
      .subscribe((res) => {
        this.formattedQuestions[marker.name] = res.map((q) =>
          this.convertRadioDataBooleanToText(q),
        );
      });
  }

  clearData() {
    this.markers = null;
    this.selectedMarkerName = null;
    this.formattedQuestions = {};
    this.memoId = null;
    this.modalMode$.next(null);
  }

  filterQuestionMarkers(markers: Marker[]) {
    return markers.filter((m) => m?.question_positions?.length > 0);
  }

  reOrderQuestions(prevIndex: number, curIndex: number) {
    if (prevIndex === curIndex) {
      return;
    }
    const questions = this.curQuestionGroup;
    moveItemInArray(questions, prevIndex, curIndex);
    questions.forEach((q, i) => {
      q.question_sequence = i + 1;
    });
  }

  confirmEditQuestion() {
    for (const name in this.formattedQuestions) {
      const marker = this.markers.find((m) => m.name === name);
      if (!marker) {
        continue;
      }

      this.formattedQuestions[name].forEach((q) => {
        let formIds: string[];
        if (
          q.form_type === 'radio_checkbox' ||
          q.form_type === 'radio'
        ) {
          formIds = q.choices.map((c) => c.form_id);
        } else {
          formIds = [q.form_id];
        }

        formIds.forEach((formId) => {
          marker.question_positions.forEach((realQuestion: any) => {
            if (
              realQuestion?.formInputId &&
              realQuestion?.formInputId === formId
            ) {
              // update sequence
              realQuestion.question_sequence = q.question_sequence;

              // update question
              realQuestion.question = q.question;

              // update choice
              if (
                realQuestion.formInputType === 'radio_checkbox' ||
                realQuestion.formInputType === 'radio'
              ) {
                const newChoice = q.choices.find(
                  (c) => c.form_id === formId,
                );
                if (newChoice) {
                  realQuestion.options.fieldValue = newChoice.text;
                }
              }
            }
          });
        });
      });
    }
  }

  validateAns(): boolean {
    // validate required
    let allPassed = true;
    this.curQuestionGroup.forEach((q) => {
      if (!this.validateRequired(q)) {
        allPassed = false;
        return;
      }
      if (!this.validateMinMax(q)) {
        allPassed = false;
        return;
      }
    });
    return allPassed;
  }

  async validateAnsFromMarker(marker: Marker): Promise<string> {
    if (
      marker?.question_positions &&
      marker?.question_positions?.length <= 0
    ) {
      throw new Error('no questions');
    }

    let questions: FormatQuestion[] = [];
    try {
      questions = await new Promise<FormatQuestion[]>(
        (resolve, reject) => {
          this.questionService
            .reformatQuestions(marker.question_positions)
            .subscribe({
              next: (res) => resolve(res),
              error: (err) => reject(err),
            });
        },
      );
    } catch (err) {
      return 'Validate Question Error';
    }

    let errMsg = null;
    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];
      if (!this.validateRequired(q)) {
        errMsg = q.errorMsg;
        break;
      }
      if (!this.validateMinMax(q)) {
        errMsg = q.errorMsg;
        break;
      }
    }
    return errMsg;
  }

  validateRequired(q: FormatQuestion): boolean {
    let passed = true;
    if (!q.require) {
      q.hasError = false;
      q.errorMsg = null;
      return passed;
    }

    if (q.form_type === 'radio_checkbox' || q.form_type === 'radio') {
      q.hasError = q.choices.every((c) => !c.data);
    } else {
      q.hasError =
        q.data === null || q.data === undefined || q.data === '';
    }

    if (q.hasError) {
      q.errorMsg =
        this.translate.currentLang === 'th' ? 'โปรดระบุ' : 'required';
      passed = false;
    }
    return passed;
  }

  validateMinMax(q: FormatQuestion): boolean {
    if (q.form_type !== 'number' || typeof q.data !== 'number') {
      q.hasError = false;
      q.errorMsg = null;
      return true;
    }

    const value = +q.data;
    const minValue = q?.options?.minValue;
    if (minValue && value < +minValue) {
      q.hasError = true;
      q.errorMsg =
        this.translate.currentLang === 'th'
          ? 'ต้องไม่น้อยกว่า ' + minValue
          : 'not less than ' + minValue;
      return false;
    }

    const maxValue = q?.options?.maxValue;
    if (maxValue && value > +maxValue) {
      q.hasError = true;
      q.errorMsg =
        this.translate.currentLang === 'th'
          ? 'ต้องไม่มากกว่า ' + maxValue
          : 'not more than ' + maxValue;
      return false;
    }

    q.hasError = false;
    q.errorMsg = null;
    return true;
  }

  saveAns() {
    if (!this.memoId) {
      throw new Error('no memoId');
    }

    const payload = this.curQuestionGroup.map((q) =>
      this.convertRadioDataTextToBoolean(q),
    );

    return this.questionService
      .saveAnsQuestions(this.memoId, payload)
      .pipe(
        tap((res) => {
          const formattedQuestions = res.questions.map((q) =>
            this.convertRadioDataBooleanToText(q),
          );
          this.formattedQuestions[this.selectedMarkerName] = [
            ...formattedQuestions,
          ];

          const marker = this.markers.find(
            (m) => m.name === this.selectedMarkerName,
          );
          if (marker) {
            marker.question_positions.forEach(
              (p: FormInputMarkerIdentity, idx) => {
                const newData = res.question_positions[idx].data; // should same index
                if (p.formInputType === 'radio') {
                  const radio = p as FormInputMarkerIdentity<'radio'>;
                  // if newData is boolean, will convert to text
                  p.data = !!newData
                    ? radio.options.fieldValue
                    : null;
                } else if (p.formInputType === 'date') {
                  p.data = newData;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  p.options.rawDate =
                    res.question_positions[idx]?.options?.rawDate;
                } else {
                  p.data = newData;
                }
              },
            );
          }
        }),
      );
  }

  convertRadioDataBooleanToText(q: FormatQuestion): FormatQuestion {
    if (q.form_type !== 'radio') {
      return q;
    }
    return {
      ...q,
      choices: q.choices.map((c) => {
        return { ...c, data: !!c.data ? c.text : null };
      }),
    };
  }

  convertRadioDataTextToBoolean(q: FormatQuestion): FormatQuestion {
    if (q.form_type !== 'radio') {
      return q;
    }
    return {
      ...q,
      choices: q.choices.map((c) => {
        return { ...c, data: !!c.data };
      }),
    };
  }
}
