import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-text-editable',
  templateUrl: './text-editable.component.html',
  styleUrls: ['./text-editable.component.scss'],
})
export class TextEditableComponent {
  @Input() text: string;
  @Output() textChange = new EventEmitter<string>();

  @Input() enableEdit = false;
  @Input() placeholder: string;
  openEdit = false;
  tmpText: string;

  openEditMode() {
    this.openEdit = true;
    this.tmpText = this.text;
  }

  closeEditMode() {
    this.openEdit = false;
    this.tmpText = null;
  }

  onConfirm() {
    this.textChange.emit(this.tmpText);
    this.closeEditMode();
  }
}
