import { Pipe, PipeTransform } from '@angular/core';
import { getQuestionColorSet } from './questions.constant';

@Pipe({
  name: 'questionColor',
})
export class QuestionColorPipe implements PipeTransform {
  transform(value: number, type: 'first' | 'second' = 'first'): any {
    return getQuestionColorSet(value, type);
  }
}
